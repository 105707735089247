/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  HashRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import 'moment-timezone';
import 'moment/locale/fr';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { checkIsLogged } from './api/v2/user';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const HomeMobile = React.lazy(() => import('./views/Home/Home'));

/**
 * protect routes, access to route only if Authorized
 */
const isAuthenticated = () => {
  // console.log(localStorage.getItem('logged'));
  // return true;

  if (localStorage.getItem('logged') === 'true') {
    return true;
  }
  return false;

  // if ('email' in localStorage) {
  //   return true;
  // }
  // return false;
};

const UnauthenticatedRoute = ({ component: Component, logged, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      !isAuthenticated()
        ? <Component {...props} />
        : <Redirect to="/" />
    )}
  />
);

const AuthenticatedRoute = ({ component: Component, logged, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      isAuthenticated()
        ? <Component {...props} />
        : <Redirect to="/login" />
    )}
  />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged: false,
    };
  }

  componentDidMount() {
    checkIsLogged().then((index) => {
      localStorage.setItem('logged', !!(index && index.data && index.data.logged));
      this.setState({
        logged: !!(index && index.data && index.data.logged),
      });
    });
  }

  render() {
    const { logged } = this.state;
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <UnauthenticatedRoute exact path="/login" name="Login" component={Login} logged={logged} />
            <Route exact path="/register" name="Register" render={(props) => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <AuthenticatedRoute exact path="/mobile/home/page" name="mobile" component={HomeMobile} logged={logged} />
            <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} logged={logged} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

// function Appp() {
//   // render() {
//   return (
//     <HashRouter>
//       <React.Suspense fallback={loading()}>
//         <Switch>
//           <UnauthenticatedRoute exact path="/login" name="Login" component={Login} />
// eslint-disable-next-line max-len
//           <Route exact path="/register" name="Register" render={(props) => <Register {...props} />} />
//           <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
//           <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
//           <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
//         </Switch>
//       </React.Suspense>
//     </HashRouter>
//   );
//   // }
// }

export default App;
