export default {
  cow: 'Vache',
  number: 'Numéro',
  cowTableTitle: 'Liste des vaches',
  tooltipShowNoteCow: 'Afficher les notes',
  tooltipButtonShowFodder2: 'Afficher aliment 2',
  tooltipDetailPanelTableCow: 'montrer plus d\'informations sur cette vache',

  cowName: 'Nom',
  tooltipCowName: 'Nom de la vache',

  cowRFID: 'Num. col.',
  tooltipCowRFID: 'Numéro de collier de la vache',

  cowIdDiet: 'Régime',
  tooltipCowIdDiet: 'Régime alimentaire de la vache',

  cowGroups: 'Lots',
  tooltipCowGroups: 'Liste des lots auxquels la vache appartient',

  cowReference: 'Référence',
  tooltipCowReference: 'Référence de la vache',

  cowLastPrFeed1: 'DerniR dose Alim. 1 (g)',
  tooltipCowLastPrFeed1: 'Dernière dose aliment 1',

  cowTotalFeedPerDay1: 'Alim. 1 conso. (g)',
  tooltipCowTotalFeedPerDay1: 'Consommation d\'aliment 1',

  cowTotalFeed1: 'TTL Alim. 1 (Kg)',
  tooltipCowTotalFeed1: 'Total aliment 1',

  cowLastPrFeed2: 'DerniR dose alim. 2 (g)',
  tooltipCowLastPrFeed2: 'Dernière dose aliment 2',

  cowTotalFeedPerDay2: 'Alim. 2 conso. (g)',
  tooltipCowTotalFeedPerDay2: 'Consommation aliment 2',

  cowTotalFeed2: 'TTL Alim. 2 (Kg)',
  tooltipCowTotalFeed2: 'Total d\'aliment 2',

  cowLastFeedTs: 'DerniR alimentat°',
  tooltipCowLastFeedTs: 'Dernière alimentation',

  cowUpdatedAt: 'M-À-J', // date of the last cow info update
  tooltipCowUpdatedAt: 'Information mise à jour à', // Dernière mise à jour des information de la vache

  cowNbDays: 'Durée (J)',
  tooltipCowNbDays: 'nombre de jours',

  cowType: 'Type',
  cowWeight: 'Poids',
  cowBirthDay: 'Date de naissance',
  cowIdMother: 'Mère',
  cowIdFather: 'Père',
  cowPortion1: 'Portion1',
  cowPortion2: 'Portion2',
  cowTotalPerRemain1: 'totalPrRemain1',
  cowTotalPerRemain2: 'totalPrRemain2',

  cowNote: 'Note',
  titleStep1: 'Général',
  titleStep2: 'Détail',
  infoStep2: 'Compléter les info de vache',
  selectCowFather: 'Sélectionner le père de vache',
  selectCowMother: 'Sélectionner la mère de vache',
  selectCowState: 'Sélectionner l\'etat de vache',
  selectCowDiet: 'Sélectionner le regime de vache',
  addCowToGroup: 'Ajouter la vache au groupes',

  cowEditNoteTitle: 'Modifier la Note de la vache',
  submitButton: 'Valider',
  cancelButton: 'Annuler',
  // message
  checkWeightMessageUpdateCow: 'Vérifier le poids',
  checkBirthdayMessageUpdateCow: 'Vérifier la date de naissance',
  checkReferenceMessageUpdateCow: 'Vérifier la référence',
  checkRFIDMessageUpdateCow: 'Vérifier le numéro',
  checkNameMessageUpdateCow: 'Vérifier le nom',

  msgCowSuccessfullyAdded: 'Vache ajoutée avec succès',
  msgCowCantBeAdded: 'La vache ne peut pas être ajoutée',

  msgCowSuccessfullyUpdated: 'Vache mise à jour avec succès',
  msgCowCantBeUpdated: 'La vache ne peut pas être mise à jour',

  msgCowSuccessfullyRemoves: 'Vache supprimée avec succès',
  msgCowCantBeRemoves: 'La vache ne peut pas être supprimée',
};
