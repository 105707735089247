export default {
  tableTitle: 'Diet list',

  dietName: 'Diet',
  tooltipDietName: 'Diet name',

  startTime: 'Start',
  tooltipStartTime: 'Diet start date',

  breakTime: 'Break',
  tooltipBreakTime: 'Diet end date',

  minInterval: 'interval',
  tooltipMinInterval: 'Interval between doses',

  // fodder 1
  foodName1: 'Food 1',
  tooltipFoodName1: 'Nom d\'aliment 1',

  maxPerDay1: 'Max / day 1 (g)',
  tooltipMaxPerDay1: 'Maximum d\'aliment par jour',

  maxPerPortion1: 'Max / portion 1 (g)',
  tooltipMaxPerPortion1: 'Dose Maximum d\'aliment par jour',

  quantityPerDay1: 'Quantity / day 1 (g)',
  tooltipQuantityPerDay1: 'Quantité d\'aliment par jour',

  //  fodder 2
  foodName2: 'Food name 2',
  tooltipFoodName2: 'Nom d\'aliment 2',

  maxPerDay2: 'Max / day 2 (g)',
  tooltipMaxPerDay2: 'Maximum d\'aliment par jour',

  maxPerPortion2: 'Max / portion 2 (g)',
  tooltipMaxPerPortion2: 'Dose Maximum d\'aliment par jour',

  quantityPerDay2: 'Quantity / day 2 (g)',
  tooltipQuantityPerDay2: 'Quantité d\'aliment par jour',

  noFodder: 'No fodder',
  // Name field entry control messages
  msgErrorFieldEmpty: 'Is required',
  msgErrorFieldMaxLength: 'Max size 20',
  msgErrorFieldisExist: 'Already used',
  msgErrorFieldStartFeedTime: 'The start time must always be less than the end time',
  msgErrorFieldEndFeedTime: 'The start time must always be less than the end time',
  msgErrorFieldInterFeedMinIT: 'Interval must be more than 15 minutes',
  msgErrorFieldMaxPerDay1: 'The maximum of food 1 per day must be positive',
  msgErrorFieldMaxPerPortion1: 'The maximum dose of food 1 per day must be positive and less than the maximum of food',
  msgErrorFieldDayQuantity1: 'The amount of food 1 per day must be positive and less than the maximum of food',
  msgErrorFieldMaxPerDay2: 'The maximum of food 2 per day must be positive',
  msgErrorFieldMaxPerPortion2: 'The maximum dose of food 2 per day must be positive and less than the maximum of food',
  msgErrorFieldDayQuantity2: 'The amount of food 2 per day must be positive and less than the maximum of food',
  msgInfoNotUpdated: 'No information is changed',

  // message api
  msgDietSuccessfullyAdded: 'the diet successfully added',
  msgDietCantBeAdded: 'the diet can\'t be added',

  msgDietSuccessfullyRemoves: 'the diet successfully removes',
  msgDietCantBeRemoves: 'the diet can\'t be removes',

  msgDietSuccessfullyUpdated: 'the diet successfully updated',
  msgDietCantBeUpdated: 'the diet can\'t be updated',

};
