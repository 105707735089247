export default {
  bodyEmptyDataSourceMessage: 'Aucun enregistrement à afficher',
  bodyAddTooltip: 'Ajouter un nouveau',
  bodyDeleteTooltip: 'Supprimer',
  bodyEditTooltip: 'Éditer',
  bodyFilterRowTooltip: 'Filtre',

  editRowDeleteText: 'Voulez-vous vraiment supprimer cette ligne?',
  editRowCancelTooltip: 'Annuler',
  editRowSaveTooltip: 'Enregistrer',

  groupingPlaceholder: 'Faites glisser les en-têtes ...',
  groupingGroupedBy: 'Regroupé par:',

  headerActions: 'Actions',

  paginationLabelDisplayedRows: '{from}-{to} of {count}',
  paginationLabelRowsSelect: 'Lignes',
  paginationLabelRowsPerPage: 'Lignes par page:',
  paginationFirstAriaLabel: 'Première page',
  paginationFirstTooltip: 'Première page',
  paginationPreviousAriaLabel: 'Page précédente',
  paginationPreviousTooltip: 'Page précédente',
  paginationNextAriaLabel: 'Page suivante',
  paginationNextTooltip: 'Page suivante',
  paginationLastAriaLabel: 'Dernière page',
  paginationLastTooltip: 'Dernière page',

  toolbarAddRemoveColumns: 'Ajouter ou supprimer des colonnes',
  toolbarNRowsSelected: '{0} ligne (s) sélectionnée (s)',
  toolbarShowColumnsTitle: 'Afficher les colonnes',
  toolbarShowColumnsAriaLabel: 'Afficher les colonnes',
  toolbarExportTitle: 'Exportation',
  toolbarExportAriaLabel: 'Exportation',
  toolbarExportName: 'Exporter au format CSV',
  toolbarSearchTooltip: 'Chercher',
  toolbarSearchPlaceholder: 'Chercher',
};
