export default function encodeData(data) {
  let formBody = [];
  Object.entries(data).forEach((item) => {
    if (item[1] !== undefined) {
      const encodedKey = encodeURIComponent(item[0]);
      const encodedValue = encodeURIComponent(item[1]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
  });
  formBody = formBody.join('&');
  return formBody;
}
