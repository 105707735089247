import { HTTP_HEADER } from '../config';
import encodeData from '../encodeData';


/**
 * this function allows the client to connect with 'login' and 'password'
 * @param {object} data
 * @example {login: 'codintek', password: 'codintek' }
 */
export function signIn(data) {
  const body = encodeData(data);
  return fetch('/api/v2/login', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * this function allows the client to disconnect, without body
 */
export function signOut() {
  return fetch('/api/v2/logout', {
    method: 'POST',
    headers: HTTP_HEADER,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * this function returns current user info
 * (full name, username, email, tel, age, isAdmin, isAro ...)
 */
export function fetchUserInfo() {
  return fetch('/api/v2/user', {
    method: 'GET',
    headers: HTTP_HEADER,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log({ err });
    });
}

/**
 *
 * @param {object} userForm
 * @example userForm = {
 *    username: 'username', // at least 6 chars
 *    email: 'aa@aa.aa', // should be valid email format
 *    firstName: 'coding',
 *    lastName: 'tek',
 *    password: 'codintek100', // should be at least 8 chars long,
 *                              better if contains Uppercase, letters, number and specific char
 *    confirmPassword: 'codintek100', should be the same as password
 * }
 */
export function signUp(userForm) {
  const body = encodeData(userForm);
  return fetch('/api/v2/signup', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log({ err });
    });
}

/**
 *
 * @param {object} form
 * @example userForm = {
 *    oldPwd: 'codintek100', should be the same as password to change
 *    newPwd: 'codintek100', // should be at least 8 chars long,
 *                            better if contains Uppercase, letters, number and specific char
 * }
 */
export function changePwd(form) {
  const body = encodeData(form);
  return fetch('/api/v2/user/pwd', {
    method: 'PUT',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log({ err });
    });
}

/**
 * check if user is logged
 */
export function checkIsLogged() {
  return fetch('/api/v2/user/isLogged', {
    method: 'GET',
    headers: HTTP_HEADER,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log({ err });
    });
}
