export default {

  group: 'Lot',
  groupNbCow: 'cow count',

  tooltipShowNoteGroup: 'Display Group Note',

  groupName: 'Nom',
  tooltipGroupName: 'Name',

  cowIdDiet: 'Diet',
  tooltipGroupIdDiet: 'Lot\'s Diet',

  groupCowsList: 'Cow list',
  tooltipGroupCowsList: 'Cow List', //  auxquels la vache appartient',
  cowNote: 'Note',

  // selectCowDiet: 'Sélectionner le regime de vache',
  // addCowToGroup: 'Ajouter la vache au groupes',

  groupEditNoteTitle: 'Modifier la Note de la vache',

  // message

  checkRFIDMessageUpdateCow: 'Vérifier le numéro',
  checkNameMessageUpdateCow: 'Vérifier le nom',

  msgGroupAddError: 'Le lot ne peut pas être ajouté',
  msgGroupAddSuccess: 'Lot ajouté avec succès',

  msgGroupEditError: 'Le lot ne peut pas être modifié',
  msgGroupEditSuccess: 'Lot mis à jour avec succès',

  msgCowGroupAddError: 'L\'association de(s) vache(s) au groupe n\'a pas réussi',
  msgGroupCowAddSuccess: 'L\'association de(s) vache(s) au groupe a réussi',

  msgGroupCowDeleteError: 'La dissociation de(s) vache(s) du lot n\'a pas pu réussir',
  msgGroupCowDeleteSuccess: 'Vache(s) dissociée(s) du lot',

  msgGroupDeleteSuccess: 'Lot supprimé avec succès',
  msgGroupDeleteError: 'Le lot ne peut pas être supprimé',

  msgGroupNothingChanged: 'Aucun changement',
  msgCowDietUpdateSuccess: 'Régime(s) de(s) vache(s) mis à jour avec succès',

  // tooltipDetailPanelTableCow: 'montrer plus d\'informations sur cette vache',

  // msgGroupDeleteSuccess: 'Group Deleted',
  // msgGroupAddError: 'could not add group',
  // msgGroupNothingChanged: 'Nothing changed',
  // msgGroupEditError: 'could not edit group',
  // msgGroupAddSuccess: 'add group succeeded',
  // msgGroupEditSuccess: 'edit group succeeded ',
  // msgCowDietUpdateSuccess: ' cow diet updated',
  // msgGroupCowAddSuccess: 'cows affected to groups',
  // msgGroupCowDeleteSuccess: 'cows removed from groups',
  // msgCowGroupAddError: 'could not affect cow to groups',
  // msgGroupCowDeleteError: 'could not remove cows from groups',
  // msgGroupDeleteError: 'could not delete group, try remove cows from group',
};
