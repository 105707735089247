import {
  SET_USER_INFO_STORE_ITEMS, REQUEST_USER_INFO, RECEIVE_USER_INFO,
} from '../actionTypes';
import { USER_INFO_INIT_STATE } from '../../utils/variables';

const initialState = { ...USER_INFO_INIT_STATE };

const userInfoStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO_STORE_ITEMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REQUEST_USER_INFO:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_USER_INFO:
      return {
        ...state,
        isFetching: false,
        apiError: action.payload.error,
        apiMsg: action.payload.message,
        ...action.payload.data,
        isLogged: !action.payload.error,
        lastLoadTs: new Date().getTime(),
      };
    default: {
      return state;
    }
  }
};

export default userInfoStore;
