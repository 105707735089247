import {
  SET_COW_STORE_ITEMS, REQUEST_COW_LIST, RECEIVE_COW_LIST,
  SET_DIET_STORE_ITEMS, REQUEST_DIET_LIST, RECEIVE_DIET_LIST,
  SET_GROUP_STORE_ITEMS, REQUEST_GROUP_LIST, RECEIVE_GROUP_LIST,
  SET_FODDER_STORE_ITEMS, REQUEST_FODDER_LIST, RECEIVE_FODDER_LIST,
  SET_USER_INFO_STORE_ITEMS, REQUEST_USER_INFO, RECEIVE_USER_INFO,
  SET_USER_CONFIG, SET_NOTIF, RECEIVE_MORE_NOTIF, REQUEST_NOTIF, INVALIDATE_NOTIF,
  SET_ACTUATOR_STORE_ITEMS, REQUEST_ACTUATOR_LIST, RECEIVE_ACTUATOR_LIST,
} from './actionTypes';

import {
  getNotifByPageLoad, getUnreadNotifCount, setIsReadNotif,
} from '../api/v2/notif';

import { getCowList } from '../api/v2/cow';
import { getDietList } from '../api/v2/diet';
import { getFodderList } from '../api/v2/fodder';
import { getGroupList } from '../api/v2/group';
import { fetchUserInfo } from '../api/v2/user';
import { getActuatorList } from '../api/v2/wiFeeder';

export const setUserConfig = (userConfig) => (
  { type: SET_USER_CONFIG, payload: userConfig }
);

export const setNotif = (notif) => (
  { type: SET_NOTIF, payload: notif }
);

export const setCowStoreItems = (cowItems) => (
  { type: SET_COW_STORE_ITEMS, payload: cowItems }
);

export const invalidateNotif = (notif) => (
  { type: INVALIDATE_NOTIF, payload: notif }
);

const requestNotif = () => (
  { type: REQUEST_NOTIF }
);

const receiveMoreNotif = ({ error = true, data = [], message = '' }, nextPage) => ({
  type: RECEIVE_MORE_NOTIF,
  payload: {
    nextPage,
    error,
    message,
    notifList: data,
  },
});

export const fetchMoreNotif = () => (dispatch, getState) => { // loadMore notif
  const { nextPage = 0, pageSize = 15 } = getState().notification;
  const {
    isInfoNotif, isSuccessNotif, isWarningNotif, isErrorNotif,
  } = getState().userConfig;
  const notifLvl = [isInfoNotif, isSuccessNotif, isWarningNotif, isErrorNotif]
    .map((i, index) => i && (index + 1))
    .filter((i) => i).join('');
  dispatch(requestNotif(nextPage));
  return getNotifByPageLoad(pageSize, nextPage, 1, notifLvl)
    .then((res = {}) => dispatch(receiveMoreNotif(res, nextPage)));
};

function shouldReadNotif(notif) {
  if (!notif || notif.isReadTs) {
    return false;
  }
  return true;
}

export const fetchNotifCount = () => (dispatch, getState) => {
  const {
    isInfoNotif, isSuccessNotif, isWarningNotif, isErrorNotif,
  } = getState().userConfig;
  const notifLvl = [isInfoNotif, isSuccessNotif, isWarningNotif, isErrorNotif]
    .map((i, index) => i && (index + 1))
    .filter((i) => i).join('');
  return getUnreadNotifCount(notifLvl)
    .then((res) => {
      if (res && res.data) {
        const { nbUnreadNotif: unreadNotifCount = 0, nbNotif: notifCount = 0 } = res.data;
        return dispatch(setNotif({ unreadNotifCount, notifCount }));
      }
      return false;
    });
};

export const postReadNotifById = (idNotif) => (dispatch, getState) => {
  const { notifList, unreadNotifCount } = getState().notification;
  const notifIndex = notifList.findIndex((item) => item.idRec === idNotif);
  const notif = { ...notifList[notifIndex] };
  if (shouldReadNotif(notif)) {
    notif.isReadTs = `${new Date().getTime()}`;
    const newNotifList = [...notifList];
    newNotifList.splice(notifIndex, 1, notif);
    dispatch(setNotif({ notifList: newNotifList, unreadNotifCount: unreadNotifCount - 1 }));
    return setIsReadNotif({ idRecs: [idNotif] })
      .then((res) => {
        if (res && res.error) {
          dispatch(setNotif({ notifList: newNotifList, unreadNotifCount }));
        }
      }).catch(() => dispatch(setNotif({ notifList, unreadNotifCount })));
  }
  return false;
};

/**
 * COW ACTIONS
 */

const requestCowList = () => ({ type: REQUEST_COW_LIST });

const receiveCowList = ({ error = true, data = [], message = '' }) => {
  const cowDates = [];
  const idCowToIdDiet = {};
  const idCowToNameMap = {};
  const idCowToIdGroups = {};
  const cowIndexToIdDietList = [];
  const cowIdGroupList = [];
  let shouldDisplayPrevDay = false;
  const cowList = data.map((i) => {
    const {
      idCow, idDiet, createdAt = '', idGroup = '', pdtf1, name,
    } = i;
    const cowIdGroups = (idGroup && idGroup.split(',').map((k) => parseInt(k, 10))) || [];
    idCowToNameMap[idCow] = name;
    idCowToIdDiet[idCow] = idDiet;
    cowIndexToIdDietList.push(idDiet);
    idCowToIdGroups[idCow] = cowIdGroups;
    cowDates.push(createdAt.substr(0, 10));
    cowIdGroupList.push(...cowIdGroups);
    if (pdtf1 > 0) {
      shouldDisplayPrevDay = true;
    }
    return {
      ...i,
      cowIdGroups,
    };
  });
  let count = 0;
  const cowDatesCount = cowDates.sort().reduce((prev, cur) => {
    prev[cur] = (prev[cur] || count) + 1;
    count += 1;
    return prev;
  }, {});
  const idDietToCowCount = cowIndexToIdDietList.reduce((prev, cur) => {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});
  const idGroupToCowCount = cowIdGroupList.reduce((prev, cur) => {
    prev[cur] = (prev[cur] || 0) + 1;
    return prev;
  }, {});
  return {
    type: RECEIVE_COW_LIST,
    payload: {
      error,
      message,
      cowList,
      cowDates,
      idCowToIdDiet,
      cowDatesCount,
      idCowToNameMap,
      cowIdGroupList,
      idCowToIdGroups,
      idDietToCowCount,
      idGroupToCowCount,
      cowIndexToIdDietList,
      shouldDisplayPrevDay,
    },
  };
};

export const fetchCowList = () => (dispatch) => { // loadMore notif
  dispatch(requestCowList());
  return getCowList()
    .then((res = {}) => dispatch(receiveCowList(res)));
};

/**
 * DIET ACTIONS
 */

export const setDietStoreItems = (dietItems) => (
  { type: SET_DIET_STORE_ITEMS, payload: dietItems }
);

const requestDietList = () => ({ type: REQUEST_DIET_LIST });

const receiveDietList = ({ error = true, data = [], message = '' }) => {
  const idDietToNameMap = {};
  const ids = [];
  const dietList = data.map((item) => {
    const {
      idDiet, name, idFodder1, idFodder2, interFeedMinIT,
    } = item;
    idDietToNameMap[idDiet] = name;
    ids.push(idDiet);
    return {
      ...item,
      idFodder1: idFodder1 === null ? 'NULL' : idFodder1,
      idFodder2: idFodder2 === null ? 'NULL' : idFodder2,
      interFeedMinIT: interFeedMinIT / 60,
    };
  });
  return {
    type: RECEIVE_DIET_LIST,
    payload: {
      ids,
      error,
      message,
      dietList,
      idDietToNameMap,
    },
  };
};

export const fetchDietList = () => (dispatch) => { // loadMore notif
  dispatch(requestDietList());
  return getDietList()
    .then((res = {}) => dispatch(receiveDietList(res)));
};

/*
 * GROUP ACTIONS
 */

export const setGroupStoreItems = (groupItems) => (
  { type: SET_GROUP_STORE_ITEMS, payload: groupItems }
);

const requestGroupList = () => ({ type: REQUEST_GROUP_LIST });

const receiveGroupList = ({ error = true, data = [], message = '' }) => {
  const idGroupToNameMap = {};
  const ids = [];
  const groupList = data.map((i) => {
    const { idGroup, name, idCow } = i;
    const groupIdCows = (idCow && idCow.split(',').map((k) => parseInt(k, 10))) || [];
    idGroupToNameMap[idGroup] = name;
    ids.push(idGroup);
    return {
      ...i,
      groupIdCows,
    };
  });
  return {
    type: RECEIVE_GROUP_LIST,
    payload: {
      ids,
      error,
      message,
      groupList,
      idGroupToNameMap,
    },
  };
};

export const fetchGroupList = () => (dispatch) => { // loadMore notif
  dispatch(requestGroupList());
  return getGroupList()
    .then((res = {}) => dispatch(receiveGroupList(res)));
};

/*
 * USER INFO ACTIONS
 */

export const setUserInfoStoreItems = (userItems) => (
  { type: SET_USER_INFO_STORE_ITEMS, payload: userItems }
);

const requestUserInfo = () => ({ type: REQUEST_USER_INFO });

const receiveUserInfo = ({ error = true, data = [], message = '' }) => {

  return {
    type: RECEIVE_USER_INFO,
    payload: {
      error,
      message,
      data: data[0],
    },
  };
};

export const fetchUser = () => (dispatch) => { // loadMore notif
  dispatch(requestUserInfo());
  return fetchUserInfo()
    .then((res = {}) => dispatch(receiveUserInfo(res)));
};

/*
 * FODDER ACTIONS
 */

export const setFodderStoreItems = (fodderItems) => (
  { type: SET_FODDER_STORE_ITEMS, payload: fodderItems }
);

const requestFodderList = () => ({ type: REQUEST_FODDER_LIST });

const receiveFodderList = ({ error = true, data = [], message = '' }) => {
  const idFodderToNameMap = {};
  const ids = [];
  data.forEach(({ idFodder, name }) => {
    idFodderToNameMap[idFodder] = name;
    ids.push(idFodder);
  });
  return {
    type: RECEIVE_FODDER_LIST,
    payload: {
      ids,
      error,
      message,
      fodderList: data,
      idFodderToNameMap,
    },
  };
};

export const fetchFodderList = () => (dispatch) => { // loadMore notif
  dispatch(requestFodderList());
  return getFodderList()
    .then((res = {}) => dispatch(receiveFodderList(res)));
};

/*
 * Actuator ACTIONS
 */

export const setActuatorStoreItems = (actuatorItems) => (
  { type: SET_ACTUATOR_STORE_ITEMS, payload: actuatorItems }
);

const requestActuatorList = () => ({ type: REQUEST_ACTUATOR_LIST });

const receiveActuatorList = ({ error = true, data = [], message = '' }) => {
  const idActuatorToNameMap = {};
  const ids = [];
  data.forEach(({ idActuator, name }) => {
    idActuatorToNameMap[idActuator] = name;
    ids.push(idActuator);
  });
  return {
    type: RECEIVE_ACTUATOR_LIST,
    payload: {
      ids,
      error,
      message,
      actuatorList: data,
      idActuatorToNameMap,
    },
  };
};

export const fetchActuatorList = () => (dispatch) => { // loadMore notif
  dispatch(requestActuatorList());
  return getActuatorList()
    .then((res = {}) => dispatch(receiveActuatorList(res)));
};
export const fetchAllData = () => (dispatch) => {
  dispatch(fetchUser());
  dispatch(fetchCowList());
  dispatch(fetchDietList());
  dispatch(fetchGroupList());
  dispatch(fetchFodderList());
  dispatch(fetchNotifCount());
  dispatch(fetchMoreNotif());
  dispatch(fetchActuatorList());
  // return true;
};
