export default {
  validate: 'Valider',
  rfidCalibration: 'RFID de Calibration',
  configuration: 'Configuration',
  notification: 'Notifications', // 'Filtre de notifications',
  nbDays: 'Nombre de jours',
  shouldDisplayFodder1Chart: 'Consommation Ali1. :',
  shouldDisplayFodder21Chart: 'Consommation Ali2. :',
  shouldDisplayNbCowChar: 'Nombre total des vaches : ',
  shouldDisplayNbVisitChart: 'Nombre des visites :',
  shouldDisplayPortionChar: 'Portion moyenne :',
  shouldDisplayFodder1ByItChar: 'Consommation / 4h: ',
  shouldDisplayPortionByItChar: 'Portion moyenne / 4h: ',
  shouldDisplayNbVisitByItChart: 'Nombre des visites / 4h : ',

  shouldDisplayInfoNotif: 'Alerte bleue',
  shouldDisplaySuccessNotif: 'Alerte verte',
  shouldDisplayWarningNotif: 'Alerte orangée',
  shouldDisplayErrorNotif: 'Alerte rouge ',

  readAllNotifTitle: 'Marquer comme lu',
  readAllNotifButtonTitle: 'Lire tout',

};
