export default {
  SystemInformation: 'System Information',
  cowLowConsumption: 'Faible consommation',
  StatusConnectivity: 'Status Connectivity',
  StatusMotor: 'Status Motor ',
  StatusRFIDReader: 'Status RFID Reader',
  StatusProxySensor: 'Proximity Sensor',
  100: 'Number detected',
  UNKNOWN_RFID: 'Number detected',
  101: 'Feed Alert',
  102: 'System Information',
};
