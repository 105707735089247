import { combineReducers } from 'redux';

import userConfig from './userConfig';
import notification from './notification';
import cowStore from './cowStore';
import dietStore from './dietStore';
import groupStore from './groupStore';
import fodderStore from './fodderStore';
import userInfoStore from './userInfoStore';
import actuatorStore from './actuatorStore';

export default combineReducers({
  notification,
  userConfig,
  cowStore,
  dietStore,
  groupStore,
  fodderStore,
  userInfoStore,
  actuatorStore,
});
