import { HTTP_HEADER, HTTP_HEADER_JSON } from '../config';
import encodeData from '../encodeData';


export function getGroupList() {
  return fetch('api/v2/group/withCow')
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function addGroup(group) {
  const body = encodeData(group);
  return fetch('api/v2/group', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

/**
 *
 * @param {object} group
 */
export function editGroup(group) {
  const body = encodeData(group);
  return fetch('api/v2/group', {
    method: 'PUT',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

/**
 *
 * @param {object} group
 */
export function deleteGroup(group) {
  const body = encodeData(group);
  return fetch('api/v2/group', {
    method: 'DELETE',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}


/**
 *
 * @param {object} groupCow
 */
export function addGroupCow(groupCow) {
  const body = encodeData(groupCow);
  return fetch('api/v2/groupCow', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}


/**
 *
 * @param {object} group
 */
export function deleteGroupCowBy2Id(group) {
  const body = encodeData(group);
  return fetch('api/v2/groupCow/2id', {
    method: 'DELETE',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

/**
 *  remove cows from groups
 * @param {object} cowsGroups
 * cowsGroups: {
 * idCow,
 * idGroups,
 * OR
 * idGroup,
 * idCows
 * }
 */
export function removeCowsFromGroups(cowsGroups) {
  const body = JSON.stringify(cowsGroups);
  return fetch('api/v2/cowsGroups', {
    method: 'DELETE',
    headers: HTTP_HEADER_JSON,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}


/**
 *  remove cows from groups
 * @param {object} cowsGroups
 * cowsGroups: {
 * idCow,
 * idGroups,
 * OR
 * idGroup,
 * idCows
 * }
 */
export function addCowsToGroups(groupsCows) {
  const body = JSON.stringify(groupsCows);
  return fetch('api/v2/cowsGroups', {
    method: 'POST',
    headers: HTTP_HEADER_JSON,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}
