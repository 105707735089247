import {
  SET_DIET_STORE_ITEMS, REQUEST_DIET_LIST, RECEIVE_DIET_LIST,
} from '../actionTypes';
import { DIET_INIT_STATE } from '../../utils/variables';

const initialState = { ...DIET_INIT_STATE };

const dietStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIET_STORE_ITEMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REQUEST_DIET_LIST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_DIET_LIST:
      return {
        ...state,
        isFetching: false,
        apiError: action.payload.error,
        apiMsg: action.payload.message,
        dietList: action.payload.dietList,
        idDietToNameMap: action.payload.idDietToNameMap,
        ids: action.payload.ids,
        // dietIndexToIdDietList: action.payload.dietIndexToIdDietList,
        lastLoadTs: new Date().getTime(),
      };
    default: {
      return state;
    }
  }
};

export default dietStore;
