export default {
  actTableTitle: 'Wifeeders',
  actSerialNColumnTitle: 'N° de Série',
  actMotor1ColumnTitle: 'Moteur 1',
  actMotor2ColumnTitle: 'Moteur 2',
  actRfidColumnTitle: 'Détecteur',
  actConnectivityColumnTitle: 'Connectivité',

  actCreatedAt: 'Date De Création',
  actLastUpdate: 'M-À-J',

  online: 'connecté',
  offline: 'pas connecté',
  ok: 'ok',
  error: 'erreur',
  warning: 'préavis',

};
