export const SET_USER_CONFIG = 'SET_USER_CONFIG';
export const SET_CHART_NB_REC = 'SET_CHART_NB_REC';

/** notif manager  */
export const SET_NOTIF = 'SET_NOTIF';
export const REQUEST_NOTIF = 'REQUEST_NOTIF';
export const INVALIDATE_NOTIF = 'INVALIDATE_NOTIF';
export const REQUEST_MORE_NOTIF = 'REQUEST_MORE_NOTIF';
export const RECEIVE_MORE_NOTIF = 'RECEIVE_MORE_NOTIF';

/** cow  */
export const SET_COW_STORE_ITEMS = 'SET_COW_STORE_ITEMS';
export const SET_COW_STORE_LIST = 'SET_COW_STORE_LIST';
export const REQUEST_COW_LIST = 'REQUEST_COW_LIST';
export const RECEIVE_COW_LIST = 'RECEIVE_COW_LIST';

/** group */
export const SET_GROUP_STORE_ITEMS = 'SET_GROUP_STORE_ITEMS';
export const SET_GROUP_STORE_LIST = 'SET_GROUP_STORE_LIST';
export const REQUEST_GROUP_LIST = 'REQUEST_GROUP_LIST';
export const RECEIVE_GROUP_LIST = 'RECEIVE_GROUP_LIST';

/** diet */
export const SET_DIET_STORE_ITEMS = 'SET_DIET_STORE_ITEMS';
export const SET_DIET_STORE_LIST = 'SET_DIET_STORE_LIST';
export const REQUEST_DIET_LIST = 'REQUEST_DIET_LIST';
export const RECEIVE_DIET_LIST = 'RECEIVE_DIET_LIST';

/** fodder */
export const SET_FODDER_STORE_ITEMS = 'SET_FODDER_STORE_ITEMS';
export const SET_FODDER_STORE_LIST = 'SET_FODDER_STORE_LIST';
export const REQUEST_FODDER_LIST = 'REQUEST_FODDER_LIST';
export const RECEIVE_FODDER_LIST = 'RECEIVE_FODDER_LIST';

/** actuator */
export const SET_ACTUATOR_STORE_ITEMS = 'SET_ACTUATOR_STORE_ITEMS';
export const SET_ACTUATOR_STORE_LIST = 'SET_ACTUATOR_STORE_LIST';
export const REQUEST_ACTUATOR_LIST = 'REQUEST_ACTUATOR_LIST';
export const RECEIVE_ACTUATOR_LIST = 'RECEIVE_ACTUATOR_LIST';

/** user info */
export const SET_USER_INFO_STORE_ITEMS = 'SET_USER_INFO_STORE_ITEMS';
export const REQUEST_USER_INFO = 'REQUEST_USER_INFO';
export const RECEIVE_USER_INFO = 'RECEIVE_USER_INFO';
export const SET_IS_LOGGED_USER = 'SET_IS_LOGGED_USER';

/** stat */
export const SET_STAT = 'SET_STAT';
export const REQUEST_STAT = 'REQUEST_STAT';
