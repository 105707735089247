import {
  SET_NOTIF, INVALIDATE_NOTIF, REQUEST_NOTIF, RECEIVE_MORE_NOTIF,
} from '../actionTypes';
import { NOTIF_INIT_STATE } from '../../utils/variables';

const initialState = { ...NOTIF_INIT_STATE };

const notification = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIF: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case INVALIDATE_NOTIF:
      return { ...state, didInvalidate: true };
    case REQUEST_NOTIF:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_MORE_NOTIF:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        page: action.payload.page,
        nextPage: action.payload.nextPage + 1,
        apiError: action.payload.error,
        apiMsg: action.payload.message,
        lastLoadTs: new Date().getTime(),
        notifList: [...state.notifList, ...action.payload.notifList
          .filter(({ idRec }) => state.ids.indexOf(idRec) === -1)],
        ids: [...new Set([...state.ids, ...action.payload.notifList.map(({ idRec }) => idRec)])],
        firstLoadTs: state.firstLoadTs ? state.firstLoadTs : new Date().getTime(),
      };
    default: {
      return state;
    }
  }
};

export default notification;
