import {
  SET_COW_STORE_ITEMS, REQUEST_COW_LIST, RECEIVE_COW_LIST,
} from '../actionTypes';
import { COW_INIT_STATE } from '../../utils/variables';

const initialState = { ...COW_INIT_STATE };

const cowStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_COW_STORE_ITEMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REQUEST_COW_LIST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_COW_LIST:
      return {
        ...state,
        isFetching: false,
        apiError: action.payload.error,
        apiMsg: action.payload.message,
        cowList: action.payload.cowList,
        cowDates: action.payload.cowDates,
        cowDatesCount: action.payload.cowDatesCount,
        idCowToIdDiet: action.payload.idCowToIdDiet,
        idCowToNameMap: action.payload.idCowToNameMap,
        cowIdGroupList: action.payload.cowIdGroupList,
        idCowToIdGroups: action.payload.idCowToIdGroups,
        idDietToCowCount: action.payload.idDietToCowCount,
        idGroupToCowCount: action.payload.idGroupToCowCount,
        shouldDisplayPrevDay: action.payload.shouldDisplayPrevDay,
        cowIndexToIdDietList: action.payload.cowIndexToIdDietList,
        // cowDates: action.payload.cowList.map((i)=>)
        // ids: [...new Set([...state.ids, ...action.payload.notifList.map(({ idRec }) => idRec)])],
        lastLoadTs: new Date().getTime(),
        // firstLoadTs: state.firstLoadTs ? state.firstLoadTs : new Date().getTime(),
      };
    default: {
      return state;
    }
  }
};

export default cowStore;
