export default {
  menuLogout: 'Logout',
  menuHome: 'Home',
  menuCow: 'Cows',
  menuGroup: 'Groups',
  menuDiet: 'Diets',
  menuFood: 'Food',
  menuStatistics: 'Statistics',
  menuWifeeder: 'Stations',
  menuHistory: 'History',
};
