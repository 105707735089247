export default {
  validate: 'valider',
  rfidCalibration: 'RFID de Calibration',
  configuration: 'Configuration',
  notification: 'Filtre des notifications',
  nbDays: 'day number',
  shouldDisplayFodder1Chart: 'Afficher Consommation Ali1. :',
  shouldDisplayFodder21Chart: 'Afficher Consommation Ali2. :',
  shouldDisplayNbCowChar: 'Afficher Nombre des Vaches : ',
  shouldDisplayNbVisitChart: 'Afficher Nombre des visits :',
  shouldDisplayPortionChar: 'Afficher Portion Moyenne :',
  shouldDisplayFodder1ByItChar: 'Afficher Consommation / 4h: ',
  shouldDisplayPortionByItChar: 'Afficher Portion moyenne / 4h: ',
  shouldDisplayNbVisitByItChart: 'Afficher Nombre des Visits / 4h : ',

  shouldDisplayInfoNotif: 'info alert',
  shouldDisplaySuccessNotif: 'success alert',
  shouldDisplayWarningNotif: 'warning alert',
  shouldDisplayErrorNotif: 'error alert ',

};
