export const MAX_LENGTH_NAME = 20;
export const MIN_INTER_FEED_TIME = 15;

export const classNameColorNotif = {
  info: 'list-group-item-accent-info list-group-item-divider',
  success: 'list-group-item-accent-success list-group-item-divider',
  warning: 'list-group-item-accent-warning list-group-item-divider',
  error: 'list-group-item-accent-danger list-group-item-divider',
};

export const classNameColorIsReadNotif = {
  info: 'list-group-item-accent-info list-group-item-divider bg-info-isRead',
  success: 'list-group-item-accent-success list-group-item-divider bg-success-isRead',
  warning: 'list-group-item-accent-warning list-group-item-divider bg-warning-isRead',
  error: 'list-group-item-accent-danger list-group-item-divider bg-danger-isRead',
};

export const roleCode = {
  guest: 0,
  admin: 1,
  client: 2,
  codintek: 3,
};

export const notifCodeMap = {
  100: 'UNKNOWN_RFID',
  UNKNOWN_RFID: 100,
  101: 'FEED_ALERT',
  FEED_ALERT: 100,
  102: 'ERROR_HARD',
  ERROR_HARD: 100,
};

export const USER_CONFIG = {
  notifLvl: '134',
  isFodder2: true,
  isInfoNotif: true,
  isSuccessNotif: false,
  isWarningNotif: true,
  isErrorNotif: true,
  globalChartNbRec: 8,
  isNbCowChart: false,
  isFodder2Chart: false,
  isNbVisitChart: true,
  isPortionChart: true,
  isFodder1Chart: true,
  isFodder1ByItChart: false,
  isPortionByItChart: false,
  isNbVisitByItChart: true,

};

export const NOTIF_INIT_STATE = {
  page: 0,
  ids: [],
  count: 0,
  apiMsg: '',
  nextPage: 0,
  notifCount: 0,
  totalCount: 0,
  notifList: [],
  updatedAt: null,
  apiError: false,
  lastLoadTs: null,
  isFetching: false,
  unreadNotifCount: 0,
  stopLoadNotif: false,
  didInvalidate: false,
};

export const indexToHomeChartMap = [
  'isFodder1Chart',
  'isFodder2Chart',
  'isNbVisitChart',
  'isPortionChart',
  'isNbCowChart',
  'isFodder1ByItChart',
  'isPortionByItChart',
  'isNbVisitByItChart',
];

export const indexToChartTitleMap = [
  'shouldDisplayFodder1Chart',
  'shouldDisplayFodder21Chart',
  'shouldDisplayNbVisitChart',
  'shouldDisplayPortionChar',
  'shouldDisplayNbCowChar',
  'shouldDisplayFodder1ByItChar',
  'shouldDisplayPortionByItChar',
  'shouldDisplayNbVisitByItChart',
];

export const indexToNotifLvlMap = [
  'isInfoNotif',
  'isSuccessNotif',
  'isWarningNotif',
  'isErrorNotif',
];

export const indexToNotifLvlTitleMap = [
  'shouldDisplayInfoNotif',
  'shouldDisplaySuccessNotif',
  'shouldDisplayWarningNotif',
  'shouldDisplayErrorNotif',
];

export const COW_INIT_STATE = {
  ids: [],
  apiMsg: '',
  cowList: [],
  cowDates: [],
  cowDatesCount: {},
  idCowToIdDiet: {},
  idCowToNameMap: {},
  idCowToIdGroups: {},
  cowIndexToIdDietList: [],
  cowIdGroupList: [],
  idDietToCowCount: {},
  idGroupToCowCount: {},

  lastUpdateTs: null,
  apiError: false,
  isFetching: true,
  didInvalidate: false,
  shouldDisplayPrevDay: false,
};

export const DIET_INIT_STATE = {
  ids: [],
  apiMsg: '',
  dietList: [],
  idDietToNameMap: {},
  dietIndexToIdDietList: [],

  apiError: false,
  isFetching: true,
  lastUpdateTs: null,
  didInvalidate: false,
};

export const GROUP_INIT_STATE = {
  ids: [],
  apiMsg: '',
  groupList: [],
  idGroupToNameMap: {},
  groupIndexToIGroupList: [],

  apiError: false,
  isFetching: true,
  lastUpdateTs: null,
  didInvalidate: false,
};

export const FODDER_INIT_STATE = {
  ids: [],
  apiMsg: '',
  fodderList: [],
  idFodderToNameMap: {},
  fodderIndexToIFodderList: [],

  apiError: false,
  isFetching: true,
  lastUpdateTs: null,
  didInvalidate: false,
};

export const ACTUATOR_INIT_STATE = {
  ids: [],
  apiMsg: '',
  actuatorList: [],
  idActuatorToNameMap: {},
  actuatorIndexToIFodderList: [],

  apiError: false,
  isFetching: true,
  lastUpdateTs: null,
  didInvalidate: false,
};

export const USER_INFO_INIT_STATE = {
  apiMsg: '',
  email: null,
  firstName: null,
  lastName: null,
  role: 'guest',
  settings: null,
  username: null,
  isLogged: false,
  apiError: false,
  isFetching: true,
  createdAt: null,
  updatedAt: null,
  lastUpdateTs: null,
};
