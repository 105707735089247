import {
  SET_ACTUATOR_STORE_ITEMS, REQUEST_ACTUATOR_LIST, RECEIVE_ACTUATOR_LIST,
} from '../actionTypes';
import { ACTUATOR_INIT_STATE } from '../../utils/variables';

const initialState = { ...ACTUATOR_INIT_STATE };

const actuatorStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTUATOR_STORE_ITEMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REQUEST_ACTUATOR_LIST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_ACTUATOR_LIST:
      return {
        ...state,
        isFetching: false,
        apiError: action.payload.error,
        apiMsg: action.payload.message,
        actuatorList: action.payload.actuatorList,
        idActuatorToNameMap: action.payload.idActuatorToNameMap,
        ids: action.payload.ids,
        lastLoadTs: new Date().getTime(),
      };
    default: {
      return state;
    }
  }
};

export default actuatorStore;
