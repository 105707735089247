export default {
  tableTitle: 'Liste des régimes',

  dietName: 'Nom',
  tooltipDietName: 'Nom de régime',

  startTime: 'Début',
  tooltipStartTime: 'date de debut de régime',

  breakTime: 'Fin',
  tooltipBreakTime: 'date de fin de régime',

  minInterval: 'Intervalle',
  tooltipMinInterval: 'Intervalle entre les doses',

  // fodder 1
  foodName1: 'Aliment 1',
  tooltipFoodName1: 'Nom d\'aliment 1',

  maxPerDay1: 'Max ali. 1 / J (g)',
  tooltipMaxPerDay1: 'Maximum d\'aliment par jour',

  maxPerPortion1: 'Dose max ali. 1 (g)',
  tooltipMaxPerPortion1: 'Dose Maximum d\'aliment par jour',

  quantityPerDay1: 'Qté ali. 1 / J (g)',
  tooltipQuantityPerDay1: 'Quantité d\'aliment par jour',

  //  fodder 2
  foodName2: 'Aliment 2',
  tooltipFoodName2: 'Nom d\'aliment 2',

  maxPerDay2: 'Max ali. 2 / J (g)',
  tooltipMaxPerDay2: 'Maximum d\'aliment par jour',

  maxPerPortion2: 'Dose max ali. 2 (g)',
  tooltipMaxPerPortion2: 'Dose Maximum d\'aliment par jour',

  quantityPerDay2: 'Qté ali. 2 / J (g)',
  tooltipQuantityPerDay2: 'Quantité d\'aliment par jour',

  noFodder: 'Pas d\'aliment',
  // Name field entry control messages
  msgErrorPositiveValue: 'doit être > 0',
  msgErrorFieldEmpty: 'Est requis',
  msgErrorFieldMaxLength: 'Taille max 20',
  msgErrorFieldisExist: 'Déjà utilisé',
  msgErrorFieldStartFeedTime: 'Debut < Fin',
  msgErrorFieldEndFeedTime: 'Debut < Fin',
  msgErrorFieldInterFeedMinIT: 'Min 15 Mn',
  msgErrorFieldMaxPerPortion1: 'doit être <= max ',
  msgErrorFieldDayQuantity1: ' doit être <=max ',
  msgErrorFieldMaxPerPortion2: ' doit être < max',
  msgErrorFieldDayQuantity2: '<= max aliment',
  msgInfoNotUpdated: 'Pas de modification',

  // message api
  msgDietSuccessfullyAdded: 'Régime ajoutée avec succès',
  msgDietCantBeAdded: 'Le régime ne peut pas être ajouté',

  msgDietSuccessfullyRemoves: 'Régime supprimée avec succès',
  msgDietCantBeRemoves: 'Le régime ne peut pas être supprimé',

  msgDietSuccessfullyUpdated: 'Régime mis à jour avec succès',
  msgDietCantBeUpdated: 'Le régime ne peut pas être mis à jour',

};
