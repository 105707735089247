import {
  SET_GROUP_STORE_ITEMS, REQUEST_GROUP_LIST, RECEIVE_GROUP_LIST,
} from '../actionTypes';
import { GROUP_INIT_STATE } from '../../utils/variables';

const initialState = { ...GROUP_INIT_STATE };

const groupStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_GROUP_STORE_ITEMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REQUEST_GROUP_LIST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_GROUP_LIST:
      return {
        ...state,
        isFetching: false,
        apiError: action.payload.error,
        apiMsg: action.payload.message,
        groupList: action.payload.groupList,
        idGroupToNameMap: action.payload.idGroupToNameMap,
        ids: action.payload.ids,
        lastLoadTs: new Date().getTime(),
      };
    default: {
      return state;
    }
  }
};

export default groupStore;
