import { HTTP_HEADER, HTTP_HEADER_JSON } from '../config';

/**
 * get all notification
 */
export function getAllNotif() {
  return fetch('/api/v2/system/notif/all', {
    method: 'GET',
    headers: HTTP_HEADER,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * Get Notif by page load
 * @param {number} pageSize
 * @param {number} page
 * @param {number} order
 * @example
 * pageSize = 5
 * page = 5
 * order = 1 or 0
 */
export function getNotifByPageLoad(pageSize, page, order, notifLvl = 134) {
  return fetch(`/api/v2/system/notif/byPage?pageSize=${pageSize}&page=${page}&order=${order}&notifLvl=${notifLvl}`)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
    });
}

/**
 * get alert by id
 * @param {number} idAlert
 * @example idAlert = 37
 */
export function getNotifById(idAlert) {
  return fetch(`/api/v2/system/notif/${idAlert}`)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
    });
}

/**
 * set alert is read
 * @param {array} params
 * @example
 * {
 * "idRecs": [1, 2, 3, 4,5]
 * }
 */
export function setIsReadNotif(params) {
  return fetch('/api/v2/system/notif/', {
    method: 'PUT',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * get unread notif count
 */
export function getUnreadNotifCount(notifLvl = '134') {
  return fetch(`/api/v2/system/notif/unread/count?notifLvl=${notifLvl}`)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      return err;
    });
}

export function readAllNotifByLvl(notifLvl = '1234') {
  return fetch('/api/v2/system/notif/all', {
    method: 'PUT',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify({ notifLvl }),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
      return err;
    });
}
