export default {
  SystemInformation: 'Information système',
  cowLowConsumption: 'Faible consommation',
  StatusConnectivity: 'Connectivité', // 'L\'état de connectivité',
  StatusMotor: 'Moteur ', // 'L\'état de moteur ',
  StatusRFIDReader: 'Détecteur de vache', //  'Lecteur Rfid', // 'L\'état de Lecteur RFID',
  StatusProxySensor: 'Detect. de Proximité',
  100: 'Numéro inconnu',
  UNKNOWN_RFID: 'Numéro inconnu',
  101: 'Faible consomation',
  102: 'Information système',
};
