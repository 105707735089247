import { HTTP_HEADER } from '../config';
import encodeData from '../encodeData';

export function getWiFeederList() {
  return fetch('api/v2/wifeeder/all')
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function addWiFeeder(wiFeeder) {
  const body = encodeData(wiFeeder);
  return fetch('api/v2/wifeeder', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function editWiFeeder(wiFeeder) {
  const body = encodeData(wiFeeder);
  return fetch('api/v2/wifeeder', {
    method: 'PUT',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function deleteWiFeeder(wiFeeder) {
  const body = encodeData(wiFeeder);
  return fetch('api/v2/wifeeder', {
    method: 'DELETE',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function getActuatorList() {
  return fetch('api/v2/actuator/all')
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}
