import { USER_CONFIG } from '../../utils/variables';
import {
  SET_CHART_NB_REC, SET_USER_CONFIG,
} from '../actionTypes';

const initialState = USER_CONFIG;

const userConfig = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHART_NB_REC: {
      const { nbRec } = action.payload;
      return {
        ...initialState,
        nbRec,
      };
    }
    case SET_USER_CONFIG: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default userConfig;
