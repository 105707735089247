import { HTTP_HEADER, HTTP_HEADER_JSON } from '../config';
import encodeData from '../encodeData';

export function getCowList() {
  return fetch('api/v2/cow/withGroup')
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function addCow(cow) {
  const body = encodeData(cow);
  return fetch('api/v2/cow', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

/**
 * this function allows the client to to add new cow
 * @param {object} data
 * @example {
    "rfid": "00000001",
    "name": "cow00000001",
    "reference": "00000001",
    "idMother": "{{idCow}}",
    "idFather": "{{idCow}}",
    "note": "this  is a cow note, write something",
    "idDiet": "1",
    "idFeedProgram": "1",
    "birthDay": "2020-01-01",
    "weight": "550",
    "type": "sein"
  }
 */
export function editCow(cow) {
  const body = encodeData(cow);
  return fetch('api/v2/cow', {
    method: 'PUT',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

/**
 * this function allows the client to delete cow
 * @param {Object} cow
 * @example {
    "idCow": "33"
    }
 */
export function deleteCow(cow) {
  const body = encodeData(cow);
  return fetch('api/v2/cow', {
    method: 'DELETE',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * this function allows the client to get cow record by id
 * @param {Object} params
 * @example params = {
    "idCow": "27",
    "fieldName": "all",
    "sTs": "0"
}
 */
export function getCowRecordById(params) {
  const body = encodeData(params);
  return fetch('/api/v2/cow/record', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * get all cow records by pages
 * @param {object} params params request
 * @example params {nbRec: 10, offset: 10}, default {nbRec: 10, offset: 0}
 */
export function getCowsRecordByPage(params) {
  const body = encodeData(params);
  return fetch('/api/v2/cows/record', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * get Nb Cow record
 */
export function getNbCowRecords() {
  return fetch('/api/v2/cow/nbRec', {
    method: 'GET',
    headers: HTTP_HEADER,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * get all notification
 */
export function getAllCowAlert() {
  return fetch('/api/v2/cow/alert/all', {
    method: 'GET',
    headers: HTTP_HEADER,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * set alert is read
 * @param {array} params
 * @example
 * {
 * "idRecs": [1, 2, 3, 4,5]
 * }
 */
export function setIsReadAlert(params) {
  return fetch('/api/v2/cow/alert', {
    method: 'PUT',
    headers: HTTP_HEADER_JSON,
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log('this an error', { err });
    });
}

/**
 * get alert by id
 * @param {number} idAlert
 * @example idAlert = 37
 */
export function getAlertById(idAlert) {
  return fetch(`/api/v2/cow/alert/${idAlert}`)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
    });
}

/**
 * Get Cow Alerts by page load
 * @param {number} pageSize
 * @param {number} page
 * @param {number} order
 * @example
 * pageSize = 5
 * page = 5
 * order = 1 or 0
 */
export function getAlertByPageLoad(pageSize, page, order) {
  return fetch(`/api/v2/cow/alert/byPage?pageSize=${pageSize}&page=${page}&order=${order}`)
    .then((res) => res.json())
    .catch((err) => {
      console.error(err);
    });
}