import { HTTP_HEADER } from '../config';
import encodeData from '../encodeData';


export function getFodderList() {
  return fetch('api/v2/fodder/all')
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function addFodder(fodder) {
  const body = encodeData(fodder);
  return fetch('api/v2/fodder', {
    method: 'POST',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function editFodder(fodder) {
  const body = encodeData(fodder);
  return fetch('api/v2/fodder', {
    method: 'PUT',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}

export function deleteFodder(fodder) {
  const body = encodeData(fodder);
  return fetch('api/v2/fodder', {
    method: 'DELETE',
    headers: HTTP_HEADER,
    body,
  })
    .then((res) => res.json())
    .catch((err) => { console.error(err); });
}
