import {
  SET_FODDER_STORE_ITEMS, REQUEST_FODDER_LIST, RECEIVE_FODDER_LIST,
} from '../actionTypes';
import { FODDER_INIT_STATE } from '../../utils/variables';

const initialState = { ...FODDER_INIT_STATE };

const fodderStore = (state = initialState, action) => {
  switch (action.type) {
    case SET_FODDER_STORE_ITEMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case REQUEST_FODDER_LIST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case RECEIVE_FODDER_LIST:
      return {
        ...state,
        isFetching: false,
        apiError: action.payload.error,
        apiMsg: action.payload.message,
        fodderList: action.payload.fodderList,
        idFodderToNameMap: action.payload.idFodderToNameMap,
        ids: action.payload.ids,
        lastLoadTs: new Date().getTime(),
      };
    default: {
      return state;
    }
  }
};

export default fodderStore;
