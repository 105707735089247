const host = '192.168.1.100';
const url = `http://${host}:11000`;
export const MICRO_SERVICES_URL = url; // 'https://wifeeder.herokuapp.com'; 
export const HTTP_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
  // 'Cache': 'no-cache'
};

export const HTTP_HEADER_JSON = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// ip = http://192.168.1.100:4000
