export default {
  consomation1parjour: 'ALIMENT 1 CONSOMMÉ / JOUR (Kg)',
  consomation2parjour: 'ALIMENT 2 CONSOMMÉ / JOUR (Kg)',
  totalnumberofvisitsperday: 'TOTAL DES VISITES / JOUR',
  numberofcows: 'NOMBRE TOTAL DE VACHES',
  meanPortionChartTitle: 'PORTION MOYENNE / JOUR',

  chartConsumptionTitle: 'Consommation (kg)',
  chartNbVisitTitle: 'Total des visits',
  chartMeanPortionTitle: 'Portion (g)',
  chartNbCowTitle: 'Nombre des vaches',

  cowTableTitle: 'Activité des vaches',
  cowRFIDColumnTitle: 'Num. col.',
  tooltipRFID: 'Numéro de collier',
  cowNameColumnTitle: 'Nom',
  tooltipCowName: 'Nom de la vache',
  cowDietColumnTitle: 'Régime',
  tooltipDiet: 'Régime',

  cowConsumptionColumnTitle: 'Conso. actuelle', // 'Aliment consommé',
  tooltipLastCowConsumption: 'Consomamtion actuelle',
  cowConsumption1ColumnTitle: 'Aliment 1 consommé',
  cowConsumption2ColumnTitle: "Conso. d'hier", // 'Aliment consommé dernier jour',

  cowLastDayConsumption: 'Conso. d\'hier',
  tooltipCowLastDayConsumption: 'Consommation d\'hier',

  cowLastVisit: 'M-À-J',
  tooltipLastVisit: 'Dernière Mise à Jour',

  toolbarSearchPlaceholder: 'Chercher',
  bodyEmptyDataSourceMessage: 'Aucune activité',

  chartDoughnutType: 'Donut',
  chartBarType: 'Bandes horizontales',
  chartBarVerticalType: 'Bandes verticales',
  dietDoughnutTitle: 'Régime',
  groupDoughnutTitle: 'Lot',
};
