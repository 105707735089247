import throttle from 'lodash.throttle';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import { createStore, compose, applyMiddleware } from 'redux';

import rootReducer from './reducers';
import { fetchAllData } from './actions';
import { loadState, saveState } from './localStorage';

const persistedState = loadState();
// const loggerMiddleware = createLogger();

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// eslint-disable-next-line no-underscore-dangle
// (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose;

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancer(
    applyMiddleware(
      thunkMiddleware, // lets us dispatch() functions
      // loggerMiddleware, // neat middleware that logs actions
    ),
  ),
  // persistedState,
);

store.subscribe(throttle(() => {
  const {
    userConfig, cowStore, dietStore, groupStore, userInfoStore, fodderStore, actuatorStore,
    // notification,
  } = store.getState();
  saveState({
    userConfig,
    cowStore,
    dietStore,
    groupStore,
    userInfoStore,
    fodderStore,
    actuatorStore,
    // notification,
  });
}, 1000));

store.dispatch(fetchAllData());

export default store;
