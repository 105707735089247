export default {

  group: 'Lot',
  groupNbCow: 'Nombre de vaches',
  submit: 'Soumettre',
  reset: 'Réinitialiser',

  tooltipShowNoteGroup: 'Afficher les notes',

  groupName: 'Nom',
  tooltipGroupName: 'Nom du lot',

  cowIdDiet: 'Régime',
  tooltipGroupIdDiet: 'Régime alimentaire du lot',

  groupCowsList: 'Liste des vaches',
  tooltipGroupCowsList: 'Liste des vaches', //  auxquels la vache appartient',
  cowNote: 'Note',

  // selectCowDiet: 'Sélectionner le regime de vache',
  // addCowToGroup: 'Ajouter la vache au groupes',

  groupEditNoteTitle: 'Modifier la Note de la vache',

  // message

  checkRFIDMessageUpdateCow: 'Vérifier le numéro',
  checkNameMessageUpdateCow: 'Vérifier le nom',

  msgGroupAddError: 'Le lot ne peut pas être ajouté',
  msgGroupAddSuccess: 'Lot ajouté avec succès',

  msgGroupEditError: 'Le lot ne peut pas être modifié',
  msgGroupEditSuccess: 'Lot mis à jour avec succès',

  msgCowGroupAddError: 'L\'association de(s) vache(s) au groupe n\'a pas réussi',
  msgGroupCowAddSuccess: 'L\'association de(s) vache(s) au groupe a réussi',

  msgGroupCowDeleteError: 'La dissociation de(s) vache(s) du lot n\'a pas pu réussir',
  msgGroupCowDeleteSuccess: 'Vache(s) dissociée(s) du lot',

  msgGroupDeleteSuccess: 'Lot supprimé avec succès',
  msgGroupDeleteError: 'Le lot ne peut pas être supprimé',

  msgGroupNothingChanged: 'Aucun changement',
  msgCowDietUpdateSuccess: 'Régime de vache mis à jour avec succès',

  // tooltipDetailPanelTableCow: 'montrer plus d\'informations sur cette vache',
};
