export default {
  menuLogout: 'Se déconnecter',
  menuHome: 'Accueil',
  menuCow: 'Vaches',
  menuGroup: 'Lots',
  menuDiet: 'Régimes',
  menuFood: 'Aliments',
  menuStatistics: 'Statistiques',
  menuWifeeder: 'Distributeurs',
  menuHistory: 'historique',
};
