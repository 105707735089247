export default {
  consomation1parjour: 'CONSUMPTION 1 PER DAY (g)',
  consomation2parjour: 'CONSUMPTION 2 PER DAY (g)',
  totalnumberofvisitsperday: 'TOTAL NUMBER OF VISITS PER DAY',
  numberofcows: 'NUMBER OF COWS',
  meanPortionChartTitle: 'PORTION MOYENNE PER DAY',

  chartConsumptionTitle: 'Consommation (kg)',
  chartNbVisitTitle: 'Total des visits',
  chartMeanPortionTitle: 'Portion (g)',
  chartNbCowTitle: 'Nombre des vaches',

  cowTableTitle: 'Cows',
  cowRFIDColumnTitle: 'RFID',
  cowNameColumnTitle: 'Cow',
  cowDietColumnTitle: 'Diet',
  cowConsumption1ColumnTitle: 'Consumption1',
  cowConsumption2ColumnTitle: 'Consumption2',

  cowLastDayConsumption: 'Yesterday Consumption',
  tooltipCowLastDayConsumption: 'Yesterday Consumption',

  cowLastVisit: 'Last visit',
  tooltipLastVisit: 'Last visit',

  toolbarSearchPlaceholder: 'Search',
  bodyEmptyDataSourceMessage: 'No cow',

  chartDoughnutType: 'Doughnut',
  chartBarType: 'Bar',
  chartBarVerticalType: 'Bar vertical',

  dietDoughnutTitle: 'Diet',
  groupDoughnutTitle: 'Lot',
};
